
.adjust-dates-set-overflow{
    overflow: hidden;
}

.text-button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none;
    border: none;
}

.text-button:disabled,
.text-button[disabled] {
    color: #9A9B9C !important;
}

.text-button:disabled span {
    color: #9A9B9C !important;
}

.action-options{
    font-weight: 500;
    font-size: 14px;
    line-height: 16.88px;
}

.adjust-border-for-todo-list-item{
    border-bottom: 1px solid #E0E1DD;
    margin-bottom: 18px;
    width: 36%;
}

.save-button-zmcc-attachments{
    max-width: 116px;
}