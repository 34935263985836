.calendar-slot {
  border-radius: 0;
  font-family: 'FrutigerNextRegular' !important;

  .header {
    background-color: #f2bdff; // Should be determined based on status and etc
    color: #000;
    padding: 3px 5px;
    font-size: 8px;
    line-height: 9.86px;
  }

  .request-type1{
    background-color: #f2bdff !important;
  }
  .request-type1-border{
    border: 3px solid #7030A0 !important;
    border-top: 0 !important;
    background-color: #F2BDFF0D !important;
  }
  .request-type2{
    background: repeating-linear-gradient(-45deg, #FF0000, #FF0000 2px, #fff 2px,  #fff 5px) !important; 
  }

  .request-type2-border{
    border: none !important;
    background-color: rgba(207, 54, 82, 0.05) !important;
  }

  .request-type3{
    background-color: #F4B084 !important;
  }
  .request-type3-border{
    border: 3px solid #FF0000 !important;
    border-top: 0 !important;
    background-color: #F4B0840D !important;
  }

  .slot-name-container {
    box-sizing: border-box;
    background-color: #00b0f00d;
    border: 3px solid #00b050;
    border-top: 0;
    color: #000;
    font-size: 12px;
    padding: 5px;
    flex-wrap: wrap;
  }
  
  .comment-slots{
    color: #000;
    font-size: 12px;
    padding: 5px;
    flex-wrap: wrap;
    text-align: center;
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
  }
}

.header3-text {
  border: none;
  position: relative;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  .slot-header-date {
    margin-top: 8px;
    font-size: 14px;
    font-family: 'FrutigerNextRegular' !important;
    text-align: left;
    margin-left: 1.5rem;
    font-weight: 400 !important;
    position: absolute;
    width: 1000px;
    top: 0;
  }

  .header-today-text {
    color: #0088d0;
    font-weight: 500 !important;
  }

  .header-week-view-time {
    flex: 1;
    text-align: center;
    border: 1px solid #e0e1dd;
    // padding-block: 2px;
    margin-top: 33px;
    padding-block: 2px;
    border-bottom: none;
    border-left: none;
    text-align: center;
  }
}

.event-item {
  padding-right: 0 !important;
}

.iqs-calendar-container {
  .scheduler-bg .scheduler-bg-table tbody tr td:nth-child(odd) {
    border-right: none !important;
  }

  .resource-table tbody tr td {
    background-color: #0f1f3e;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
  }

  .timeline-event{
    transform: translate(0, 23%);
  }

  .resource-table tbody tr td[data-resource-id='comments'],
  .resource-table tbody tr td[data-resource-id='systems'],
  .resource-table tbody tr td[data-resource-id='equipment'],
  .resource-table tbody tr td[data-resource-id='other'],
  .resource-table tbody tr td[data-resource-id='person'] {
    background-color: #6ab0e2;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 16.94px;
    color: #000000;
  }
  .resource-table tbody tr td[data-resource-id$='__location'] {
    background-color: #c6daf24d;
    color: #000000;
  }

  .scheduler-bg-table tbody tr[data-id='comments'] td,
  .scheduler-bg-table tbody tr[data-id='systems'] td,
  .scheduler-bg-table tbody tr[data-id='equipment'] td,
  .scheduler-bg-table tbody tr[data-id='other'] td,
  .scheduler-bg-table tbody tr[data-id='person'] td {
    border-right: none !important;
  }
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  z-index: 1000;
  font-size: 12px;
  color: #000;
  border-radius: 5px;
}
.info-icon {
  // position: static; /* Make it the context for the tooltip */
  // display: inline-block;
  height: 16px;
  width: 16px;
  font-size: 10px;
  text-align: center;
  margin-left: 10px !important;
  border-radius: 50%;
  border: solid 1.5px #0088d0;
  color: #0088d0 !important;
}

.info-icon:hover {
  .tooltip-content {
    display: block;
  }
}

.resource-table-checkbox .newui-small-checkmark {
  height: 10px !important;
  width: 10px !important;
  border-radius: 0 !important;
  color: white !important;
  border: 1px solid #fff !important;
  overflow: hidden;
  p {
    font-size: 14px !important;
  }
}
.chevron-down {
  transform: rotate(-270deg);
  cursor: pointer !important;
}

.chevron-up {
  transform: rotate(270deg);
  cursor: pointer !important;
}

.calendar-legends-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .container-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 15px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .legend-box {
    width: 50px;
    height: 15px;
    gap: 0px;
    border-radius: 21px;
    margin-right: 16px;
    font-weight: 500;
  }

  .legend-title {
    font-weight: 600;
    font-size: 14px;
  }
}

.calendar-add-button {
  width: 150px;
}

.iqs-display-date-time {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
}


.card {
  background-color: #ffffff;
  padding: 20px;
  width: 300px;
  // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: left;
}

.details p {
  margin: 5px 0;
  font-size: 12px;
}

.details p strong {
  color: #333;
}

.view-resource {
  display: block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #0088D0;
  color: #fff !important;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  width: 112px;
}

.today-button-text{
  font-weight: 300;
  font-size: 16px;
  line-height: 19.36px;
  padding: 0.3rem 0.75rem !important;
  border: 1px solid #E0E1DD;
  margin-inline: 0.5rem;
  color: #0088D0;
}

.request-type2-time-color{
  background-color: #CF3652;
  padding-inline: 2px;
  border-radius: 1px;
  color: #fff;
}

.iqs-calendar-active{
  .ant-popover {
    background-color: #F8F8F8;
    white-space: nowrap;
    // z-index: 1000;
    font-size: 12px;
    // color: #000 !important;
    border-radius: 5px;
    transform: translateX(20px) !important;

    .ant-popover-inner {
      background-color: #F8F8F8;
      color: #000 !important;
      min-width: 250px !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
      padding-inline: 10px;
      padding-block: 5px;
      max-width: 300px;
      overflow: hidden;
      white-space: wrap;
      text-overflow: ellipsis;
    }
  }
  .ant-popover .ant-popover-arrow {
    display: none !important;
    transform: none;
  }
  .ant-popover-inner div {
    color: #000 !important; /* Apply black color to all inner elements */
  }
  .ant-popover-inner button {
    width: 74px;
    padding: 7px 10px 7px 10px;
    color: #fff !important;
    border: none;
    background-color: #0088D0;
    font-weight: bold;
  }
}

.event-popover-container{
  .event-dot{
    width: 10px;
    height: 10px;
    margin-top: 3px;
    border-radius: 50px;
    margin-right: 10px;
  }
}

.edit-button{
  width: 74px;
  color: #fff !important;
}