@import "resources/styles/variables";

.app-schedular-container {
  font-family: $font-weight-regular, $font-weight-bold !important;
  margin-top: 30px;

  .scheduler {
    margin: 0 0 20px 0;
  }

  table td, table th {
    padding: 0 !important;
    font-size: 12px;
    color: #000;
    font-weight: normal;
  }

  .single-event {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selected-from-date {
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      border-right: solid 1px #ffffff;
      min-width: 24px;
      padding: 0 4px;
      text-align: center;
    }

    .selected-to-date {
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      border-left: solid 1px #ffffff;
      min-width: 24px;
      padding: 0 4px;
      text-align: center;

      .to-date-text {
        padding-top: 3px !important;
      }

      .delete-button {
        padding: 0;
      }
    }

    .event-title {
      font-size: 10px;
      font-weight: bold;
    }
  }

  .timeline-event {
    .radius-right {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .radius-left {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
  .slot-booked-kit {
    position: relative;
    background-color: $color-light-blue;
    border-radius: 25px !important;
    overflow: hidden;
    .slot-booked-kit-blocked-period{
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $color-cool-grey;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    p {
      visibility: hidden;
    }
    .display-title-value{
      visibility: visible !important;
      font-size: 15px;
    }
  }
  .add-height-for-slot{
    height: 56px !important;
    border-radius: 0px !important;
    display: flex;
    line-height: 60px;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
  .slot-operator-schedule{
    position: relative;
    border-radius: 25px !important;
    overflow: hidden;
    .slot-operator-schedule-blocked-period{
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $color-cool-grey;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    p {
      visibility: hidden;
    }
    .disaply-title-value-for-operator{
      visibility: visible !important;
      font-size: 15px;
      overflow: hidden !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      color: #fff;
    }
  }
  .operator-slot-booked-height{
    height: 57px !important;
    border-radius: 0px !important;
    display: flex;
    flex-direction: column;
    line-height: 60px;
    text-align: center;
  }
  .booked-kit {
    position: relative;
    background-color: $color-dark-blue;
    border-radius: 3px;
    overflow: hidden;

    .booked-kit-delivery-period {
      position: absolute;
      // border-top-left-radius: 3px;
      // border-bottom-left-radius: 3px;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: $color-light-blue;
    }
    .booked-kit-return-period {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $color-light-blue;
      // border-top-right-radius: 3px;
      // border-bottom-right-radius: 3px;

      .booked-kit-reconditioning {
        height: 100%;
        background-color: $color-dark-green;
      }
    }

    .booked-kit-overdue-period {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $color-red;
      // border-top-right-radius: 3px;
      // border-bottom-right-radius: 3px;
    }

    .booked-kit-blocked-period {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $color-cool-grey;
      // border-top-right-radius: 3px;
      // border-bottom-right-radius: 3px;
    }

    p {
      visibility: hidden;
    }

    .display-value-for-booked-kit{
      visibility: visible !important;
      color: black;
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #fff;
    }
  }
  .add-height-for-booked-slot{
    height: 56px !important;
    border-radius: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 56px;
    text-align: center;
    background-color: $color-cool-grey;
  }

  .resource-table, .scheduler-content-table {
    tr:nth-child(even) {
      // background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .resource-table, .scheduler-content-table-with-uneven-bg {
    tr:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .event-item {
    padding-right: 0 !important;
  }

  .event-popover {
    background-color: #000000;
    color: #ffffff;
    width: 200px;
  }

}
/* Default Popover Arrow Styles */
.ant-popover .ant-popover-inner {
  background-color: #000000;
}

.ant-popover .ant-popover-inner-content {
  padding: 4px 8px;
  color: #ffffff;
}

/* New conditional styles */
.new-ant-popover .ant-popover-inner {
  background-color: #ffffff;
}

.new-ant-popover .ant-popover-inner-content {
  padding: 4px 8px;
  color: #000000;
}

/* Default Popover Arrow Styles */
.ant-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #000;
  border-left-color: #000;
}

/* New Conditional Popover Arrow Styles */
.new-ant-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.new-ant-popover.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.new-ant-popover.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #fff;
  border-left-color: #fff;
}

table.resource-table {
  text-align: left;

  td, th {
    padding: 0 8px !important;
  }
  td, td p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  td p.slotName {
    width: 9rem;
  }

  td p.location-short-name {
    width: 4rem;
  }

  .info-message {
    display: none;
    top: unset;
  }

  td p.input-info:hover + .info-message {
    display: block;
    margin-top: -25px;
  }

  td p.info-icon-kit:hover + .info-message {
    display: block;
    margin-top: -25px;
  }
}

.location-short-name {
  color: $white;
  text-align: start;
}

.new-location-short-name{
  color: $color-cool-grey;
  text-align: start;
}

.resource-table .header3-text {
  color: $color-blue;
}


.view-styling {
  display: flex;
  flex-direction: row-reverse;
}

.view-type-btn-container{
  margin-right: 6px;
  margin-bottom: 6px;
}

.view-type-buttons-selected{
  background-color: #0088d0;
  color: #fff;
}


.show-blue-color-for-op{
  background-color: #0b1f40 !important;
}

.not-show-blue-color-for-op{
  background-color: #808080 !important;
}
.defective-kit{
  color: red !important;
}

.blue-bg {
  color: white !important;
  font-weight: 900 !important;
}

.info-icon-kit{
  height: 16px;
  width: 16px;
  font-size: 10px;
  text-align: center;
  margin-left: 10px !important;
  border-radius: 50%;
  border: solid 1.5px $color-off-white;
}

.right-header{
  font-size: 14px !important;
  font-weight: bold !important;
}  

.container-class{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-date {
  font-size: 20px;
  line-height: 24px;
  margin-right: 1rem !important;
  font-weight: 400;
}

.today-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 19.36px;
  padding: 0.3rem 0.75rem !important;
  border: 1px solid #E0E1DD;
  margin-inline: 0.5rem;
}

.left-header-container > :last-child{
  border-right: 1px solid #0088D0;
}

.left-header-toggle-btn {
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 19.36px;
  min-width: 120px;
  min-height: 23px;
  border: 1px solid #0088D0;
  border-right: none;
}

.calendar-navigation-container{
  position: absolute;
  // top: 10px;
  z-index: 10;
}