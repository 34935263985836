@import 'resources/styles/variables';


.button {
  position: relative;
  width: 100%;
  padding: 8px 12px;
  text-decoration: none;
  border: 0;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 16px;
  font-weight: bold;
  display: flex;

  &:disabled {
    cursor: default;
  }
}

.button-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* variants */

.contained {
  color: $white;
  background-color: $color-blue;

  &:disabled:not(.button-loading) {
    opacity: 0.65;
  }
}

.outlined {
  color: $color-blue;
  background-color: transparent;
  border: 1px solid $color-blue;

  &:disabled:not(.button-loading) {
    color: $color-cool-grey;
    border: solid 1px $color-cool-grey;
  }
}

.ghost {
  color: $color-blue;
  background-color: transparent;

  &:disabled:not(.button-loading) {
    color: $color-cool-grey;
  }
}

.button-icon {
  fill: currentColor;
}

.button-loading .button-content {
  visibility: hidden;
}

.button-loading .button-loading-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

/* text */

.right-text {
  order: 1;

  & + .button-icon {
    margin-right: 12px;
  }
}

.left-text {
  order: 0;

  & + .button-icon {
    margin-left: 12px;
  }
}

/* alignment */
.content-center {
  justify-content: center;
  align-items: center;
}

.content-space-between {
  justify-content: space-between;
}

.btn-max-width-300 {
  max-width: 300px;
}

.reserve-button {
  width: auto;
}

@media(max-width: 768px) {
  .reserve-button {
    width: 100%;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.button.active {
  border-color: blue; /* Highlight color */
  border-top-width: 3px; /* Adjust border width */
}


.adjust-new-version-button-text{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.new-ui-disabled-button{
  color: gray;
}